var generic = generic || {};
var site = site || {};

(function ($) {
  Drupal.behaviors.emailSignupBlockV1 = {
    attached: false,
    nodes: {},
    selectors: {
      modules: '.js-email-signup-block-v1',
      emailContainer: '.js-email-signup-block-inner',
      emailForm: '.js-email-signup-block__form',
      emailSuccess: '.js-email-signup-block__success',
      emailError: '.js-email-signup-block__error',
      emailContent: '.email-signup-block__form-content',
      emailPromotionError: 'input[name="error_required_pc_email_promotion"]',
      emailAddressError: 'input[name="error_required_pc_email_address"]',
      emailPrivacyError: 'input[name="error_required_accepted_privacy"]',
      emailPromotionPresent: 'input[name="PC_EMAIL_PROMOTIONS_PRESENT"]',
      emailPrivacyPresent: 'input[name="ACCEPTED_PRIVACY_POLICY_PRESENT"]',
      emailAddress: 'input[name="PC_EMAIL_ADDRESS"]',
      emailPromotions: 'input[name="PC_EMAIL_PROMOTIONS"]',
      emailPrivacy: 'input[name="ACCEPTED_PRIVACY_POLICY"]',
      emailAction: 'input[name="ACTION"]',
      emailAcceptedCommercial: 'input[name="ACCEPTED_COMMERCIAL"]',
      emailLabel: 'label',
    },
    attach: function (context) {
      var self = this;
      var $modules = $(self.selectors.modules, context);

      if (self.attached) {
        return;
      }
      self.attached = true;

      $modules.each(function () {
        var $module = $(this);
      
        self.getDom($module);
        self.initEmailSignupBlock($module);
      });
    },
    getDom: function ($module) {
      var self = this;

      $module.nodes = {};
      $module.nodes.$emailContainer = $(self.selectors.emailContainer, $module);
      $module.nodes.$emailForm = $(self.selectors.emailForm, $module);
      $module.nodes.$emailSuccess = $(self.selectors.emailSuccess, $module);
      $module.nodes.$emailError = $(self.selectors.emailError, $module);
      $module.nodes.$emailContent = $(self.selectors.emailContent, $module);
      $module.nodes.$emailPromotionError = $(self.selectors.emailPromotionError, $module);
      $module.nodes.$emailAddressError = $(self.selectors.emailAddressError, $module);
      $module.nodes.$emailPrivacyError = $(self.selectors.emailPrivacyError, $module);
      $module.nodes.$emailPromotionPresent = $(self.selectors.emailPromotionPresent, $module);
      $module.nodes.$emailPrivacyPresent = $(self.selectors.emailPrivacyPresent, $module);
      $module.nodes.$emailAddress = $(self.selectors.emailAddress, $module);
      $module.nodes.$emailPromotions = $(self.selectors.emailPromotions, $module);
      $module.nodes.$emailPrivacy = $(self.selectors.emailPrivacy, $module);
      $module.nodes.$emailAction = $(self.selectors.emailAction, $module);
      $module.nodes.$emailAcceptedCommercial = $(self.selectors.emailAcceptedCommercial, $module);
      $module.nodes.$emailLabel = $(self.selectors.emailLabel, $module);
    },
    initEmailSignupBlock: function ($module) {
      var self = this;

      if (!$module.nodes.$emailContainer) {
        return null;
      }

      // hide the newsletter signup in the footer if they are opted into email promtions
      // but we need to wait until the user data is loaded which is done after all the data is loaded
      $(document).on('user.loaded', function () {
        var user = generic.user.getUser();

        if (user.pc_email_optin === 1) {
          $module.nodes.$emailContainer.hide();
        }
      });

      $module.nodes.$emailLabel.once().on('click', function (e) {
        var $label = $(this);
        var inputId = $label.attr('for');
        var $input = $module.find(`input#${inputId}`);

        e.preventDefault();
        $input.prop('checked', !$input.prop('checked')).trigger('change');
      });

      $module.nodes.$emailForm.once('email_signup').on('submit', function (submitEvt) {
        var emailPromotionsChecked;
        var emailPrivacyChecked;
        var action;
        var preValidationErrors = [];
        var messages = [];
        var fields = [];
        var paramObj = {};
        var formField;

        submitEvt.preventDefault();
        $module.nodes.$emailSuccess.addClass('hidden');
        $module.nodes.$emailError.addClass('hidden');
        $module.nodes.$emailContent.removeClass('hidden');

        if ($module.nodes.$emailPromotions.attr('type') === 'radio') {
          emailPromotionsChecked = $module.nodes.$emailPromotions.filter(':checked').val();
        } else {
          emailPromotionsChecked = $module.nodes.$emailPromotions.prop('checked');
        }

        if ($module.nodes.$emailPrivacyPresent.val() === '1') {
          if ($module.nodes.$emailPrivacy.attr('type') === 'radio') {
            emailPrivacyChecked = $module.nodes.$emailPrivacy.filter(':checked').val();
          } else {
            emailPrivacyChecked = $module.nodes.$emailPrivacy.prop('checked');
          }
        }
        // set the ACTION for the email signup based on the whether the PC_EMAIL_PROMOTIONS is checked
        // its not required to sign up so we dont want it set by default
        action = emailPromotionsChecked ? 'set' : 'unset';
        $module.nodes.$emailAction.val(action);

        if ($module.nodes.$emailAddress.val() === '' && $module.nodes.$emailAddressError.val()) {
          preValidationErrors.push({ key: 'required.pc_email_address.email_signup' });
        }
        if ($module.nodes.$emailPromotionPresent.val() === '1') {
          if (!emailPromotionsChecked && $module.nodes.$emailPromotionError.val()) {
            preValidationErrors.push({ key: 'required.pc_email_promotions.email_signup' });
          }
        }
        if ($module.nodes.$emailPrivacyPresent.val() === '1') {
          if (!emailPrivacyChecked && $module.nodes.$emailPrivacyError.val()) {
            preValidationErrors.push({ key: 'required.privacy.email_signup' });
          }
        }

        if (preValidationErrors.length > 0) {
          messages = self.collateMessages($module, preValidationErrors);
        }
        if (messages.length > 0) {
          $module.nodes.$emailError.empty();
          $module.nodes.$emailForm.addClass('error');
          $module.nodes.$emailError.html(messages).removeClass('hidden');
        } else {
          // we want to get all the fields in code because 'serialize' encodes potentially bad emails and decode doesn't decode '+' as spaces
          fields = [
            'PC_EMAIL_ADDRESS',
            'PC_EMAIL_PROMOTIONS',
            'PC_EMAIL_PROMOTIONS_PRESENT',
            'ACTION',
            'LAST_SOURCE',
            'ORIGINAL_SOURCE',
            'LANGUAGE_ID',
            'COUNTRY_ID',
            'redirect_or_text',
            'redirect',
          ];
          
          if ($module.nodes.$emailAcceptedCommercial.length > 0) {
            fields.push('ACCEPTED_COMMERCIAL');
          }

          if ($module.nodes.$emailPrivacy.length > 0) {
            fields.push('ACCEPTED_PRIVACY_POLICY');
          }

          // loop through all the fields and get the values
          fields.forEach(function (field) {
            formField = $(`input[name=${field}]`, $module);

            // for the unchecked checkboxes we want to send empty strings to backend for processing
            if (formField.is(':checkbox') && !formField.prop('checked')) {
              paramObj[field] = '';
            } else if (formField.is(':radio')) {
              paramObj[field] = $(`input[name=${field}]:checked`).val();
            } else {
              paramObj[field] = formField.val();
            }
          });
          paramObj['_SUBMIT'] = 'email_signup';

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [paramObj],
            onSuccess: function () {
              $module.nodes.$emailContent.addClass('hidden');
              $module.nodes.$emailSuccess.removeClass('hidden');
            },
            onFailure: function (jsonRpcResponse) {
              messages = self.collateMessages($module, jsonRpcResponse.getMessages());
              $module.nodes.$emailError.empty();
              $module.nodes.$emailForm.addClass('error');
              $module.nodes.$emailError.html(messages).removeClass('hidden');
            }
          });
        }
      });
    },
    collateMessages: function ($module, messages) {
      var messageArray = [];

      if (Array.isArray(messages)) {
        messages.forEach(function (message) {
          if (
            message.key === 'required.pc_email_promotions.email_signup' &&
            $module.nodes.$emailPromotionError.val()
          ) {
            messageArray.push($module.nodes.$emailPromotionError.val());
          } else if (
            message.key === 'required.pc_email_address.email_signup' &&
            $module.nodes.$emailAddressError.val()
          ) {
            messageArray.push($module.nodes.$emailAddressError.val());
          } else if (
            message.key === 'required.privacy.email_signup' &&
            $module.nodes.$emailPrivacyError.val()
          ) {
            messageArray.push($module.nodes.$emailPrivacyError.val());
          } else {
            messageArray.push(message.text);
          }
        });
        return messageArray.join('<br />');
      }
      return;
    },
  };
})(jQuery);
